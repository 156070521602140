import React from "react";

export const Photo = ({ collage, collagewp, caption, altText, small }) => (
  <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
    <figure>
      <picture>
        <source srcSet={collagewp} type="image/webp" />
        <source srcSet={collage} type="image/jpeg" />
        <img
          className={
            small
              ? `rounded-lg shadow-lg mx-auto md:h-96 md:w-84`
              : "rounded-lg shadow-lg mx-auto"
          }
          src={collage}
          alt={altText}
        />
      </picture>
      <div className="mx-auto text-center">
        <figcaption>{caption}</figcaption>
      </div>
    </figure>
  </div>
);
