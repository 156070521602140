import React from "react";

import collage from "../static/about/collage.png";
import collagewp from "../static/about/collage.webp";
import reading from "../static/sandyabout/reading.png";
import sandy from "../static/sandyabout/sandy2.png";
import travel from "../static/sandyabout/travel.png";
import weaving from "../static/sandyabout/weaving.png";
import readingwebp from "../static/sandyabout/reading.webp";
import sandywebp from "../static/sandyabout/sandy2.webp";
import travelwebp from "../static/sandyabout/travel.webp";
import weavingwebp from "../static/sandyabout/weaving.webp";

import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { Photo } from "../components/Photo";

const About = () => (
  <Layout
    title="Sandy"
    description="Hi I'm Sandy! I decided to teach wax carving to make wax carving available to you so you can go ahead and fall in love with it yourself. You will learn through video tutorials, written handouts and have the ability to ask questions."
  >
    <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <PageTitle title="Hi I’m Sandy!" subtitle="" />

          <Photo
            collage={sandy}
            collagewp={sandywebp}
            altText="Photo of Sandy wearing a red metalsmith society shirt in front of a batik wall hanging"
            small
          />

          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              In 2012 I fell in love with making jewellery. It was PURE LUCK! I
              was looking for a hobby, something to get me out of the house, but
              absolutely not a sport. The ‘10 week introduction to traditional
              goldsmithing skills’ sounded interesting, and it was close enough
              that I could cycle there. Perfect, I signed up.
            </p>
          </div>
        </div>

        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            I was in love 15 minutes into the first class when all I’d done was
            try to pierce a straight line!
          </p>
          <p>
            Wax carving was just one of the techniques I learned and I didn’t
            have strong feelings straight away. Love happened in 2018. Jussi (my
            husband) and I decided to travel through Asia full time. I packed a
            few essential hand tools, metal, and wax in my backpack, and hoped I
            would figure out how to make on the road.
          </p>
          <p>I did figure it out!</p>
        </div>

        <Photo
          collage={collage}
          collagewp={collagewp}
          caption="A selection of the benches I made on the road."
          altText="A collage a me working behind alternative benches."
        />

        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            And I quickly realized there’s only so much you can do in metal
            until it’s time to solder. I started exclusively carving wax. The
            more I carved, the better I got. The better I got, the braver I got.
            The braver I got, the more I experimented and tried new things. The
            more I experimented and tried new things, the better I got. I
            practiced, I failed, I tried again, I played, I experimented. And
            somewhere in that time I fell in love with wax carving!
          </p>
          <p>
            Starting Wax Carvers was another moment of luck. ‘I think you should
            teach’ is something Jussi has told me throughout the years. I wasn’t
            sure how to go about it until one day I realized I should just focus
            on wax carving!
          </p>
        </div>

        <h2 className="mt-12 mb-6 block text-3xl text-center leading-6 tracking-tight text-blue-700 sm:text-4xl">
          What else do I love besides jewellery?
        </h2>

        <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
          Reading!
        </h3>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Sitting on the balcony in the sun to read. Lying on the couch and
            read. Drinking a glass of wine in a bar or at home and read. Going
            to bed early and read. Visiting bookshops and buying books to read.
            Here’s my{" "}
            <a
              href="https://www.goodreads.com/user/show/25274462-sandy-pfaff"
              target="_blank"
              rel="noopener noreferrer"
            >
              Goodreads profile.
            </a>
            &nbsp;I don’t write reviews but maybe my star ratings can help you
            decide on your next read. Add me as a friend!
          </p>

          <Photo
            collage={reading}
            collagewp={readingwebp}
            caption="Sometimes I get lucky and find a hammock!"
            altText="Sandy lying in a hammock reading a book"
            small
          />
        </div>

        <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
          Travel!
        </h3>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            We stopped full time travel, but still do a big trip every year. A
            mix of nature things and city life with the occasional beach day is
            the ideal itinerary. I always look for something jewellery related
            to do as well. Here are my <a href="/fun-stuff">recommendations!</a>
          </p>
          <Photo
            collage={travel}
            collagewp={travelwebp}
            caption="Looong walk up a mountain"
            altText="Me walking up a set of stairs on a mountain, clearly tired"
            small
          />
        </div>

        <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
          Creativity!
        </h3>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            When I started that first class in 2012 I realized I’m a creative
            person. Who knew! In an ideal world I would be a woman of leisure
            who just learns and masters new crafts. In the real world I join the
            occasional course and learn the basics of new crafts. The best part
            about learning other crafts is that it gives me new ideas for pieces
            to make and techniques to try out with wax carving.
          </p>
          <Photo
            collage={weaving}
            collagewp={weavingwebp}
            caption="Learning backstrap weaving in Guatemala"
            altText="Me in Antigua learning backstrap weaving!"
            small
          />
        </div>

        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            That’s some highlights of me, Sandy the person. Want to know about
            me, <a href="/about">Wax Carvers?</a>
          </p>
        </div>
      </div>
    </div>

    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>
);
export default About;
